import * as React from "react";
import LogoSVG from "./../assets/svg/logo.svg";
import {useContext} from "react";
import {GlobalContext} from "./Context";
import TransitionLink from 'gatsby-plugin-transition-link'
import Fade from "../transitions/Fade";
import {trigger} from "./Events";
import Cursor from "./Cursor";


interface LogoProps {
    additionalClasses?: string,
    state?: any,
    breakLapPlus?: boolean
}

const Logo = ({additionalClasses, state, breakLapPlus} : LogoProps) => {
    const {setCursorState} = useContext(GlobalContext);
    const classes = [
        'c-logo',
    ];

    if (additionalClasses)
        classes.push(additionalClasses)

    if (state === Logo.WHITE)
        classes.push('c-logo__white')

    if (state === Logo.GREEN)
        classes.push('c-logo__green')

    if (state === Logo.YELLOW)
        classes.push('c-logo__yellow')

    if (state === Logo.PINK)
        classes.push('c-logo__pink')

    if (state === Logo.BLUE)
        classes.push('c-logo__blue')

    if (breakLapPlus)
        classes.push('c-logo__breakLapPlus')
    else
        classes.push('c-logo__breakLap')

    return (
        <div className={classes.join(' ')}>
            <TransitionLink
                to={"/"}
                className={"c-logo__link"}
                onMouseEnter={() => {
                    trigger('cursor:click');
                }}
                onMouseOut={() => {
                    trigger('cursor:unclick');
                }}
                onClick={() => {
                    trigger('cursor:unclick');
                }}
                            entry={{delay: Fade.anim_delay}}
                            exit={{
                                length: Fade.anim_length,
                                state: {fromNav: true},
                                trigger: (details) => Fade(details)}}><LogoSVG className={"c-logo__svg"}/></TransitionLink>
        </div>
    )
};

Logo.DEFAULT = 0;
Logo.WHITE = 1;
Logo.GREEN = 2;
Logo.YELLOW = 3;
Logo.PINK = 4;
Logo.BLUE = 5;

export default Logo;
