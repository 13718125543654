import {gsap, Sine} from "gsap";
import { trigger } from "../components/Events";


const Fade = (details) => {
    // const destinationURI = details.node.baseURI;
    trigger('page:out', details);
    return null;
}

interface AnimateElement {
    target: string,
    type: number,
    delay?: number
}

Fade.animate = (elements: AnimateElement[]) => {
    const timeline = gsap.timeline()

    for (let element of elements) {
        if (element.type === Fade.TYPE_OPACITY) {
            timeline.to(element.target, {
                opacity: 0,
                ease: Sine.easeOut,
                duration: 0.6
            }, element.delay || 0)
        } else if (element.type === Fade.TYPE_MOVE) {
            timeline.to(element.target, {
                y: 100,
                opacity: 0,
                ease: Sine.easeOut,
                duration: 0.6
            }, element.delay || 0)
        }
    }
}

Fade.TYPE_OPACITY = 0;
Fade.TYPE_MOVE = 1;


Fade.anim_length = 1.2;
Fade.anim_delay = 0.6;

export default Fade;
